import { Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AVAILABLE_YEARS } from "../salario-utils";
import { useParams } from "react-router-dom";

export default function YearSelector(props) {
  const { path } = props;
  const { year: yearParam } = useParams();
  var year;
  if (yearParam) {
    year = yearParam;
  } else {
    year = AVAILABLE_YEARS[AVAILABLE_YEARS.length - 1];
  }
  const [currentYear, setCurrentYear] = useState(year);
  const navigate = useNavigate();
  const handleYearChange = (e) => {
    setCurrentYear(e.target.value);
    if (!path || path === "") {
      navigate(`/${e.target.value}`);
    } else {
      navigate(`/${path}/${e.target.value}`);
    }
  };

  useEffect(() => {
    setCurrentYear(year);
  }, [year]);

  return (
    <Select
      sx={{
        alignSelf: "center",
        mx: 1,
        fontSize: "33px",
      }}
      size="small"
      value={currentYear}
      onChange={handleYearChange}
    >
      {AVAILABLE_YEARS.map((year) => {
        return (
          <MenuItem sx={{ fontSize: "20px" }} value={year} key={year}>
            {year}
          </MenuItem>
        );
      })}
    </Select>
  );
}
