import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { getValuesFromHash } from "./salario-api";
import { Button, Card, CardHeader, Checkbox, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { PaddedDiv } from "./AppStyles";

export default function SharedPage() {
  const { t, i18n } = useTranslation();
  let { hash } = useParams();
  const [erro, setErro] = useState(false);
  const [data, setData] = useState({
    input: {
      salario: "",
      situacao: "",
      dependentes: "",
      incapacidade: "",
      valorSubAlimentacao: "",
      cartaoSubAlimentacao: "",
      diasTrabalho: "",
      valorSegSocial: "",
      duodecimos: "",
    },
    output: {
      netSalary: "",
      taxValue: "",
      taxRate: "",
      taxMarginalLimit: "",
      taxEffectiveLimit: "",
      foodAllowance: "",
      socialSecurity: "",
      totalCostCompany: "",
      grossSalaryCompany: "",
      tsuCompany: "",
      foodAllowanceCompany: "",
    },
  });
  const navigate = useNavigate();
  const newForm = () => navigate("/");

  useEffect(() => {
    getValuesFromHash(
      hash,
      function done(response) {
        if (response.data !== undefined) {
          setErro(false);
          setData(response.data);
        } else {
          setErro(true);
          toast("Erro: Não encontro hash");
        }
      },
      function error(err) {
        setErro(true);
        toast("Erro: " + err);
      },
    );
  }, [hash]);

  function _renderNetSalaryCardHeader() {
    return (
      <>
        <Typography variant="h6" sx={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
          {t("results-table-salary")}
          <Typography sx={{ ml: 1 }} variant="subtitle2">
            {t("results-table-salary-subheader")}
          </Typography>
        </Typography>
        <Typography variant="h6">
          {parseFloat(data.output.netSalary + data.output.foodAllowance).toFixed(2)} €
        </Typography>
      </>
    );
  }

  function _renderGrossSalaryCardHeader() {
    return (
      <>
        <Typography variant="h6" sx={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
          {t("results-table-gross-salary-company")}
        </Typography>
        <Typography variant="h6">{parseFloat(data.output.grossSalaryCompany)} €</Typography>
      </>
    );
  }

  function _renderCardHeader() {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 1,
        }}
      >
        {data.input.type === "liquido" && _renderNetSalaryCardHeader()}
        {data.input.type === "bruto" && _renderGrossSalaryCardHeader()}
      </Box>
    );
  }

  function _renderCardFormHeader() {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 1,
        }}
      >
        <Typography variant="h6" sx={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
          {t("share-page-form")}
        </Typography>
      </Box>
    );
  }

  function _renderSituacaoString(situacao) {
    if (situacao === "CASADO_DOIS_TITULARES") {
      situacao = "Casado Dois Titulares";
    }
    if (situacao === "CASADO_UNICO_TITULAR") {
      situacao = "Casado Unico Titular";
    }
    if (situacao === "NAO_CASADO") {
      situacao = "Não Casado";
    }
    return situacao;
  }

  function _formatPercentage(value) {
    return parseFloat((value * 100).toFixed(2));
  }

  function _renderSharedPage() {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
            mb: 2,
          }}
        >
          <Typography sx={{ mt: 1 }} variant="h4">
            {t("share-page-your-simulation")} {data.input.type}
          </Typography>
        </Box>
        <Card
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            mt: 5,
            borderRadius: "16px",
          }}
        >
          <CardHeader
            sx={{ px: 3, background: (theme) => theme.palette.primary.main }}
            title={_renderCardFormHeader()}
            titleTypographyProps={{
              color: (theme) => theme.palette.primary.contrastText,
            }}
          ></CardHeader>
          <Box sx={{ py: 2, px: 3 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 1,
              }}
            >
              {data.input.type === "liquido" && <Typography variant="body1">{t("share-page-gross-salary")}</Typography>}
              {data.input.type === "bruto" && <Typography variant="body1">{t("share-page-net-salary")}</Typography>}
              <Typography variant="body1">{data.input.salario} €</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 1,
              }}
            >
              <Typography variant="body1">{t("salary-form-irs-situacao")}</Typography>
              <Typography variant="body1">{_renderSituacaoString(data.input.situacao)}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 1,
                mr: 1.5,
              }}
            >
              <Typography variant="body1">{t("share-page-dependentes")}</Typography>
              <Typography variant="body1">{data.input.dependentes}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                mt: -0.1,
                mb: -0.9,
              }}
            >
              <Typography variant="body1">{t("salary-form-irs-incapacidade")}</Typography>
              <Checkbox disabled checked={data.input.incapacidade} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 1,
              }}
            >
              <Typography variant="body1">{t("share-page-Subsidio-alimentacao")}</Typography>
              <Typography variant="body1">{data.input.valorSubAlimentacao} €</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 1,
                mr: 1.5,
              }}
            >
              <Typography variant="body1">{t("share-page-dias")}</Typography>
              <Typography variant="body1">{data.input.diasTrabalho}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                mt: -0.1,
                mb: -0.9,
              }}
            >
              <Typography variant="body1">{t("share-page-cartao-refeicao")}</Typography>
              <Checkbox disabled checked={data.input.cartaoSubAlimentacao} />
            </Box>
          </Box>
          <CardHeader
            sx={{ px: 3, background: (theme) => theme.palette.primary.main }}
            title={_renderCardHeader()}
            titleTypographyProps={{
              color: (theme) => theme.palette.primary.contrastText,
            }}
          ></CardHeader>
          <Box sx={{ py: 2, px: 3 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Typography variant="body1">{t("results-table-net")}</Typography>
              <Typography variant="body1">{data.output.netSalary} €</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Typography variant="body1">{t("results-table-sub-alimentacao")}</Typography>
              <Typography variant="body1">{data.output.foodAllowance} €</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Typography variant="body1">{t("results-table-tax-rate")}</Typography>
              <Typography variant="body1">{_formatPercentage(data.output.taxRate)} %</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Typography variant="body1">{t("results-table-tax-value")}</Typography>
              <Typography variant="body1">{data.output.taxValue} €</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Typography variant="body1">{t("results-table-social-security")}</Typography>
              <Typography variant="body1">{data.output.socialSecurity} €</Typography>
            </Box>
            <Divider sx={{ mt: 3, mb: 2 }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Typography variant="body1">{t("results-table-company-cost")}</Typography>
              <Typography variant="body1">{data.output.totalCostCompany} €</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Typography variant="body1" marginLeft="16px">
                {t("results-table-gross-salary-company")}
              </Typography>
              <Typography variant="body1">{data.output.grossSalaryCompany} €</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Typography variant="body1" marginLeft="16px">
                {t("results-table-company-sub-alimentacao")}
              </Typography>
              <Typography variant="body1">{data.output.foodAllowanceCompany} €</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Typography variant="body1" marginLeft="16px">
                {t("results-table-tsu-company")}
              </Typography>
              <Typography variant="body1">{data.output.tsuCompany} €</Typography>
            </Box>
          </Box>
        </Card>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={newForm} variant="contained">
            {t("share-page-new-simulation")}
          </Button>
        </Box>
      </>
    );
  }

  function _renderErrorHash() {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
            mb: 2,
          }}
        >
          <Typography sx={{ mt: 1 }} variant="h1">
            {t("error-page-error-404")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
            mb: 2,
          }}
        >
          <Typography sx={{ mt: 1 }} variant="h4">
            {t("share-page-simulation-not-found")}
          </Typography>
        </Box>
      </>
    );
  }

  return <PaddedDiv>{erro ? _renderErrorHash() : _renderSharedPage()}</PaddedDiv>;
}
